import Vue from 'vue'
import VueGtm from 'vue-gtm';
import './plugins/vuetify'
import router from './router'
import store from './store'
import App from './App.vue'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import VueCurrencyInput from "vue-currency-input";

import Base from './layout/wrappers/baseLayout.vue';
import Auth from './layout/wrappers/pagesLayout.vue';
import Info from './layout/wrappers/infoLayout.vue';

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import WindowHelper from './mixins/WindowHelper'
import InputControlMixin from './mixins/InputControl'
import VuejsDialog from 'vuejs-dialog';

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue-select/dist/vue-select.css';
import VueTheMask from 'vue-the-mask'
import vSelect from 'vue-select'
import JsonExcel from './components/Excel/JsonExcel'
import VeeValidate from 'vee-validate';
import { getDomainInfoByCache } from "@/helpers";
import VueClipboard from "vue-clipboard2";
import UUID from "vue-uuid";
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;

const domainInfo = getDomainInfoByCache();

Vue.config.productionTip = false;
Vue.component('tags-input', VoerroTagsInput);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin)
Vue.use(VuejsDialog);
Vue.use(VueTheMask);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
Vue.use(VueClipboard);
Vue.use(UUID);

// Sentry.init({
//   Vue: Vue,
//   dsn: process.env.VUE_APP_SENTRY_DSN,
// });

if (process.env.NODE_ENV !== "development") {
  Vue.config.devtools = false;
}
Vue.component('downloadExcel', JsonExcel)

const pluginOptions = {
    /* see config reference */
    globalOptions: {
        locale: "en-GB",
        currency: null,
        currencyDisplay: "hidden",
        valueRange: undefined,
        hideNegligibleDecimalDigitsOnFocus: true,
        precision: { min: 2, max: 6 },
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        autoDecimalMode: false,
        allowNegative: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
    },
};
Vue.use(VueCurrencyInput, pluginOptions);

Vue.mixin(WindowHelper)
Vue.mixin(InputControlMixin)

Vue.component('tags-input', VoerroTagsInput);
Vue.component('vue-select', vSelect)
Vue.component('default-layout', Base);
Vue.component('auth-layout', Auth);
Vue.component('info-layout', Info);

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
});
